import { Link, router, usePage } from '@inertiajs/react';
import { AppShell, Box, Burger, Container, Group, Image, Stack, Text, useMantineTheme } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconLogin2, IconUser, IconWallet } from '@tabler/icons-react';
import { useEffect } from 'react';

import goldframerLogo from '@/images/logo.svg';
import SiteFooter from '@/pages/site/layouts/SiteFooter.jsx';

import classes from './SiteNav.module.css';

export default function SiteNav({ showFooter = true, children }) {
  const auth = usePage().props?.auth;

  const [opened, { toggle }] = useDisclosure();
  const collapseBreakpoint = 'md';
  const theme = useMantineTheme();

  // check mobile mantine way
  const isMobileS = useMediaQuery('(max-width: 374px)');

  // Close navbar if route changes
  useEffect(() => {
    return router.on('start', _event => {
      if (opened) {
        toggle();
      }
    });
  }, [opened]);

  const links = (mobile = false) => (
    <>
      <Link href={route('site.art-sales')} className={classes.control}>
        ART SALES
      </Link>
      <Link href={route('site.how-it-works')} className={classes.control}>
        HOW IT WORKS
      </Link>
      <Box c={'gfdark.4'} style={{ cursor: 'default' }} className={classes.control}>
        MARKETPLACE{' '}
        <Text span fz={'xs'} c={'gf.7'}>
          Coming Soon
        </Text>
      </Box>
      {mobile ? (
        <>
          <Link href={route('site.about')} className={classes.control}>
            AB0UT
          </Link>
          <a href="https://blog.goldframer.com" className={classes.control}>
            BLOG
          </a>
          {!!auth?.user && (
            <Link href={route('site.account.wallet')} className={classes.control}>
              WALLET
            </Link>
          )}
        </>
      ) : null}
    </>
  );

  return (
    <AppShell
      classNames={{
        root: classes.root,
        main: showFooter ? classes.main : classes.mainNoFooter,
        footer: classes.footer,
      }}
      header={{ height: theme.other.headerHeight }}
      navbar={{ width: 300, breakpoint: collapseBreakpoint, collapsed: { desktop: true, mobile: !opened } }}
    >
      <AppShell.Header>
        <Container pr={0}>
          <Group h="60">
            <Burger opened={opened} onClick={toggle} hiddenFrom={collapseBreakpoint} size="sm" />
            <Group justify="space-between" style={{ flex: 1 }} wrap={'nowrap'} gap={0}>
              <Group>
                <Link href={'/'}>
                  <Image src={goldframerLogo} />
                </Link>

                <Group gap={0} visibleFrom={collapseBreakpoint}>
                  {links()}
                </Group>
              </Group>

              {/* Mobile Header */}
              <Group ml="xl" gap={0} hiddenFrom={collapseBreakpoint} wrap={'nowrap'}>
                {!!auth?.user && (
                  <Link href={route('site.account')} className={classes.control}>
                    <Group gap={'xs'} c="var(--color-gf)" wrap={'nowrap'}>
                      <IconUser />{' '}
                      <Text c="var(--color-main)" size="inherit" hidden={isMobileS} lh={1}>
                        {auth?.user?.name}
                      </Text>
                    </Group>
                  </Link>
                )}
              </Group>

              {/* Header */}
              <Group ml="xl" gap={0} visibleFrom={collapseBreakpoint}>
                {!auth?.user && (
                  <>
                    <a href="/login" className={classes.control}>
                      <Group gap={'xs'} c="var(--color-gf)" wrap={'nowrap'}>
                        <IconLogin2 />{' '}
                        <Text c="var(--color-main)" size="inherit">
                          LOGIN
                        </Text>
                      </Group>
                    </a>
                    <a href="/register" className={classes.control}>
                      <Text c="var(--color-gf)" size="inherit">
                        SIGN UP
                      </Text>
                    </a>
                  </>
                )}

                {!!auth?.user && (
                  <>
                    <Link href={route('site.account.wallet')} className={classes.control}>
                      <Group gap={'xs'} c="var(--color-gf)">
                        <IconWallet />{' '}
                        <Text c="var(--color-main)" size="inherit">
                          WALLET
                        </Text>
                      </Group>
                    </Link>

                    <Link href={route('site.account')} className={classes.control}>
                      <Group gap={'xs'} c="var(--color-gf)">
                        <IconUser />{' '}
                        <Text c="var(--color-main)" size="inherit">
                          {auth?.user?.name}
                        </Text>
                      </Group>
                    </Link>
                  </>
                )}
              </Group>
            </Group>
          </Group>
        </Container>
      </AppShell.Header>

      {/* Mobile Nav */}
      <AppShell.Navbar py="md" px={'lg'}>
        <Stack align="stretch" justify="space-between" h={'100%'} fz={'xl'}>
          <div>{links(true)}</div>
          <div>
            {!auth?.user && (
              <Group justify={'space-between'}>
                <a href="/register" className={classes.control}>
                  <Text fz={'inherit'} c={'gf'}>
                    SIGN UP
                  </Text>
                </a>
                <a href="/login" className={classes.control}>
                  <Group gap={'xs'} c="var(--color-gf)">
                    <IconLogin2 />{' '}
                    <Text c="gfdark.9" fz="inherit">
                      LOGIN
                    </Text>
                  </Group>
                </a>
              </Group>
            )}
            {!!auth?.roles?.includes('admin') && (
              <>
                <a href="/panel" className={classes.control}>
                  PANEL
                </a>
              </>
            )}
            {!!auth?.user && (
              <>
                <a href="/logout" className={classes.control}>
                  LOGOUT
                </a>
              </>
            )}
          </div>
        </Stack>
      </AppShell.Navbar>

      <AppShell.Main>{children}</AppShell.Main>

      {showFooter && (
        <AppShell.Footer withBorder={false}>
          <SiteFooter />
        </AppShell.Footer>
      )}
    </AppShell>
  );
}
